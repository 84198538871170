import { formatDateTimeByDefault } from '@/services/date.js';

export const scaleIndexAdapter = (items) => {
  return items.map(item => makeScaleItem(item))
}

export const makeScaleItem = (item) => {
  return {
    value: item.sten,
    uuid: item.uuid,
    name: item.title,
    grades: item.grade,
    is_required: item.is_required
  }
}

export const makeMarkItem = (item) => {
  return {
    createdAt: formatDateTimeByDefault(item.created_at),
    fullName: item.expert,
    scales: item.scales.map(scale => {
      return {
        value: scale.sten,
        name: scale.title,
        uuid: scale.uuid
      }
    })
  }
}