<template>
  <div class="mt-filters">
    <app-dropdown
      v-if="searchVisibility"
      class="mt-filters__dropdown mt-filters__dropdown_search"
    >
      <template #header>
        <mt-filters-header-item
          :icon="SearchIcon"
          :active="!!search?.value"
          label="Поиск"
        />
      </template>
      <template #body>
        <app-input-search
            v-model="computedSearch.value"
            hide-details
        />
      </template>
    </app-dropdown>
    <app-dropdown
      v-if="sort !== null && sortVisibility"
      class="mt-filters__dropdown"
    >
      <template #header>
        <mt-filters-header-item
          :icon="SortIcon"
          :active="!!sort?.field"
          label="Сортировка"
        />
      </template>
      <template #body>
        <div
          class="mt-filters__list-item"
          v-for="(col, index) in sortCols"
          :key="index"
          v-show="col.sortable"
          :class="{
            'mt-filters__list-item_active': col.isSorted,
          }"
          @click="
            () => {
              handleChangeSort(col)
              $emit('change')
            }
          "
        >
          <span class="mt-filters__list-item--title">{{ col.title }}</span>
          <component
            v-if="col.isSorted"
            :is="
              col.sortType === 'asc' ? SortAscendingIcon : SortDescendingIcon
            "
            class="mt-filters__list-item__sort-icon"
          />
        </div>
      </template>
    </app-dropdown>
    <app-dropdown v-if="filterVisibility" class="mt-filters__dropdown">
      <template #header>
        <mt-filters-header-item
          :icon="FilterIcon"
          :active="!!filters?.length"
          label="Фильтр"
        />
      </template>
      <template #body>
        <mt-tree-list
          :cols="filterCols"
          :filters="filters"
          @changeFilter="changeFilter"
        >
          <template #header>
            <div class="mt-filters__filters-list" v-if="filters?.length">
              <div
                class="mt-filters__filters-item"
                v-for="(filter, index) in filters"
                :key="index"
              >
                <template v-if="filter.type === 'range'">
                  <span style="margin-right: 5px">
                    {{ `${filter.title}: ` }}
                  </span>
                  <span v-if="!!filter.value.from">
                    {{ `от ${filter.value.from}` }} </span
                  >&nbsp;
                  <span v-if="!!filter.value.to">
                    {{ `до ${filter.value.to}` }}
                  </span>
                </template>
                <template v-if="filter.type === 'time'">
                  <span style="margin-right: 5px">
                    {{ `${filter.title}: ` }}
                  </span>
                  <div>
                    <span v-if="!!filter.value.from">
                      {{ `от ${filter.value.from}` }} </span
                    >&nbsp;
                    <span v-if="!!filter.value.to">
                      {{ `до ${filter.value.to}` }}
                    </span>
                  </div>
                </template>
                <div
                  class="mt-filters__filters-body"
                  v-if="filter.type === 'list'"
                >
                  {{ `${filter.title}: ` }}
                  {{ filter.list.join(', ') }}
                </div>
                <div
                  class="mt-filters__filters-body"
                  v-if="filter.type === 'string'"
                >
                  {{ `${filter.title}: ` }}
                  "{{ filter.value }}"
                </div>
                <div
                  class="mt-filters__filters-body"
                  v-if="filter.type === 'datetime'"
                >
                  <div>
                    <span>
                      {{ `${filter.title || filter.field}: ` }}
                    </span>
                    <div>
                      <template v-if="filter.value.from">
                        {{ `от ${ filter.value.from }` }}
                      </template>&nbsp;
                      <template v-if="filter.value.to">
                        {{ `до ${ filter.value.to }` }}
                      </template>
                    </div>
                  </div>
                </div>
                <close-icon
                  class="mt-filters__filters-body__close"
                  @click="deleteFilter(index)"
                />
              </div>
            </div>
          </template>
        </mt-tree-list>
      </template>
    </app-dropdown>
    <app-dropdown v-if="colsVisibility" class="mt-filters__dropdown">
      <template #header>
        <mt-filters-header-item
          :icon="ColsIcon"
          :active="isColsActive"
          label="Столбцы"
        />
      </template>
      <template #body>
        <template v-for="(col, index) in computedCols" :key="index">
          <div class="mt-filters__list-item" v-show="col.showable">
            <span>{{ col.title }}</span>
            <mt-checkbox v-model="col.visible" @change="$emit('change')" />
          </div>
        </template>

        <mt-button class="mt-treelist__button" @click="resetCols()">
          {{ toggleColVisibilityBtnLabel }}
        </mt-button>
      </template>
    </app-dropdown>
  </div>
</template>

<script>
import mtIcon from '@/components/UI/mtIcon/mtIcon.vue'
import mtCheckbox from '@/components/UI/mtCheckbox/index.vue'
import mtInput from '@/components/UI/mtInput/mtInput.vue'
import mtPopover from '@/components/UI/mtPopover/mtPopover.vue'
import mtTreeList from '@/components/UI/mtFiltersView/mtFiltersView.vue'
import SortAscendingIcon from '@/components/icons/SortAscendingIcon.vue'
import SortDescendingIcon from '@/components/icons/SortDescendingIcon.vue'
import AppDropdown from '@/components/UI/AppDropdown/AppDropdown.vue'
import MtButton from '@/components/UI/mtButton/index.vue'
import CloseIcon from '@/components/UI/mtSvg/svg/CloseIcon.vue'
import { deepClone } from '@/services/utils.js'
import SortIcon from '@/components/icons/SortIcon.vue'
import FilterIcon from '@/components/icons/FilterIcon.vue'
import SearchIcon from '@/components/icons/SearchIcon.vue'
import MtFiltersHeaderItem from '@/components/UI/mtFilters/mtFiltersHeaderItem.vue'
import ColsIcon from '@/components/icons/ColsIcon.vue'
import AppInputSearch from "@/components/UI/AppInputSearch/AppInputSearch.vue";

export default {
  name: 'mtFilters',
  components: {
    AppInputSearch,
    ColsIcon,
    MtFiltersHeaderItem,
    SearchIcon,
    FilterIcon,
    SortIcon,
    CloseIcon,
    MtButton,
    AppDropdown,
    SortDescendingIcon,
    SortAscendingIcon,
    mtIcon,
    mtCheckbox,
    mtInput,
    mtPopover,
    mtTreeList,
  },
  props: {
    cols: {
      type: Array,
      default: () => [],
    },
    sort: {
      type: Object,
      default: null,
    },
    filters: {
      type: [Object, Array],
      default: () => [],
    },
    search: {
      type: Object,
      default: () => ({}),
    },
    filtersSettings: {
      type: [Array, Object],
      default: () => [],
    },
    searchVisibility: {
      type: Boolean,
      default: true,
    },
    sortVisibility: {
      type: Boolean,
      default: true,
    },
    filterVisibility: {
      type: Boolean,
      default: true,
    },
    colsVisibility: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      colsDefaultValues: [],
      localSortCounter: 1,
    }
  },
  computed: {
    ColsIcon() {
      return ColsIcon
    },
    FilterIcon() {
      return FilterIcon
    },
    SortIcon() {
      return SortIcon
    },
    SearchIcon() {
      return SearchIcon
    },
    SortDescendingIcon() {
      return SortDescendingIcon
    },

    SortAscendingIcon() {
      return SortAscendingIcon
    },

    sortCols() {
      return this.computedCols.map((item) => ({
        ...item,
        isSorted: item.stub === this.sort?.field,
        sortType: this.sort?.order,
      }))
    },

    filterCols() {
      let filters = []

      if (this.filtersSettings.length > 0) {
        this.computedCols.forEach((col) => {
          let hasCol = false

          this.filtersSettings.forEach((fs) => {
            if (col.stub === fs.field) {
              hasCol = true

              if (!!fs.options) {
                fs.options.forEach((option) => {
                  option['value'] = false
                })
              }

              filters.push({
                ...fs,
                ...col,
              })
            }
          })

          if (!hasCol) {
            filters.push({
              ...col,
            })
          }
        })
      } else {
        filters = this.computedCols
      }

      return filters.filter((filter) => filter.filterable)
    },

    computedSearch: {
      get() {
        return this.search
      },
      set(newValue) {
        this.$emit('update:search', newValue)
      },
    },

    isColsActive() {
      if (!this.colsDefaultValues.length)
        this.colsDefaultValues = deepClone(this.computedCols)

      return (
        JSON.stringify(this.computedCols) !==
        JSON.stringify(this.colsDefaultValues)
      )
    },

    computedCols: {
      get() {
        return this.cols
      },
      set(newValue) {
        this.$emit('update:cols', newValue)
      },
    },

    allColsAreVisible() {
      return this.computedCols
        .filter((col) => col.showable)
        .every((col) => col.visible)
    },

    toggleColVisibilityBtnLabel() {
      return this.allColsAreVisible ? 'Скрыть все' : 'Показать все'
    },
  },
  methods: {
    handleChangeSort(col) {
      const isSameField = col.stub === this.sort?.field;
      const newOrder = this.sort?.order === 'asc' ? 'desc' : 'asc';

      if (isSameField && this.sort.order === 'desc') {
        // Reset sorting when the same field is clicked twice with descending order
        this.$emit('update:sort', null);
      } else {
        // Update sorting either by switching the order or changing the field
        this.$emit('update:sort', {
          field: col.stub,
          order: isSameField ? newOrder : 'asc',
          isSorted: true,
        });
      }
    },
    deleteFilter(index) {
      let filters = []
      this.filters.forEach((item, idx) => {
        if (idx !== index) filters.push(item)
      })

      this.$emit('update:filters', filters)
      this.$emit('change')
    },
    changeFilter() {
      this.$emit('change')
    },

    resetCols() {
      const allColsAreVisible = this.allColsAreVisible
      this.computedCols = this.computedCols
        .filter((col) => col.showable)
        .map((col) => {
          col.visible = !allColsAreVisible
          return col
        })

      this.$emit('change')
    },
  },
}
</script>

<style lang="scss" src="./mtFilters.scss" />
